import { keyframes, styled } from "styled-components";

type ImageCarouselProps = {
  images: string[];
  borderRadius?: string;
  height?: string;
  width?: string;
};

const ImageCarousel = (props: ImageCarouselProps) => {
  const scroll = keyframes`
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-379px * ${props.images.length}));
    }
    `;
  const SlideTrack = styled.div`
    animation: ${scroll} 45s linear infinite;
    display: flex;
    align-items: center;
    height: 100%;
    width: ${379 * props.images.length * 2}px;
  `;

  return (
    <div className="slider">
      <SlideTrack>
        {props.images.map((image) => (
          <div className="slide">
            <img
              style={{
                borderRadius: props.borderRadius,
                height: props.height
                /*width: props.width,*/
              }}
              src={image}
              alt=""
            />
          </div>
        ))}
        {props.images.map((image) => (
          <div className="slide">
            <img
              style={{
                borderRadius: props.borderRadius,
                height: props.height,
                width: props.width,
              }}
              src={image}
              alt=""
            />
          </div>
        ))}
      </SlideTrack>
    </div>
  );
};

ImageCarousel.defaultProps = {
  borderRadius: "0",
  height: "auto",
  width: "auto",
};

export default ImageCarousel;
