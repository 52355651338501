interface SpecProps {
    icon: JSX.Element;
    title: string;
}

export default function Spec(props: SpecProps) {
    return (
        <div className="spec-wrapper">
            {props.icon}
            <h3 style={styles.title}>{props.title}</h3>
        </div>
    )
}
const styles = {
    title: {
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
    }
};