import logo from "../assets/logo.png"

const altText = "Hacknowledge logo"

interface LogoProps {
    altTextOverride?: string;
    width?: string | number;
    height?: string | number;
    style?: React.CSSProperties;
}

export default function Logo(props: LogoProps) {
    return (
        <img
            src={logo}
            alt={props.altTextOverride ?? altText}
            width={props.width}
            height={props.height}
            style={props.style}
        />
    )
}