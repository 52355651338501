import { useEffect, useState } from "react";

interface EventCountdownProps {
    date: Date;
}

interface Countdown {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

function computeRemaining(eta: Date): Countdown {
    const now: Date = new Date();
    const diff: number = eta.getTime() - now.getTime();
    const seconds: number = Math.floor(diff / 1000);
    const minutes: number = Math.floor(seconds / 60);
    const hours: number = Math.floor(minutes / 60);
    const days: number = Math.floor(hours / 24);
    return {
        days: days <= 0 ? 0 : days,
        hours: (hours % 24) <= 0 ? 0 : hours % 24,
        minutes: (minutes % 60) <= 0 ? 0 : minutes % 60,
        seconds: (seconds % 60) <= 0 ? 0 : seconds % 60,
    };
}

const pluralize = (n: number, unit: string): string => n === 1 ? unit : unit + "s";

export default function EventCountdown(props: EventCountdownProps) {
    const [remaining, setRemaining] = useState<Countdown>({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(computeRemaining(props.date));
        }, 1000);
        setRemaining(computeRemaining(props.date));
        return () => clearInterval(interval);
    }, [props.date, setRemaining]);

    return (
        <div style={styles.container}>
            <div style={styles.wrapper}>
                <div style={styles.timeWrapper}>
                    <span style={styles.time}>{remaining.days}</span>
                    <span style={styles.unit}>{pluralize(remaining.days, "day")}</span>
                </div>
                <div style={styles.timeWrapper}>
                    <span style={styles.time}>{remaining.hours}</span>
                    <span style={styles.unit}>{pluralize(remaining.hours, "hour")}</span>
                </div>
                <div style={styles.timeWrapper}>
                    <span style={styles.time}>{remaining.minutes}</span>
                    <span style={styles.unit}>{pluralize(remaining.minutes, "minute")}</span>
                </div>
                <div style={styles.timeWrapper}>
                    <span style={styles.time}>{remaining.seconds}</span>
                    <span style={styles.unit}>{pluralize(remaining.seconds, "second")}</span>
                </div>
            </div>
        </div>
    );
}

const styles = {
    container: {
        display: "flex",
        padding: "16px 56px",
        flexDirection: "column" as "column",
        alignItems: "center",
        gap: "24px",
        borderRadius: "24px",
        background: "rgba(255, 255, 255, 0.02)",
        backdropFilter: "blur(18px)",
    },
    wrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flexStart",
        gap: "24px",
    },
    time: {
        fontSize: "40px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
    },
    unit: {
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 300,
        lineHeight: "normal",
    },
    timeWrapper: {
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "center",
        gap: "-7px",
    }
}