import card_background from "../card_background.png";

export type PlanningCardProps = {
  title: string;
  description: string;
  time: string;
  index: number;
};

const PlanningCard = (props: PlanningCardProps) => {
  return (
    <div
      className="planning-card"
      style={{
        backgroundImage: `url(${card_background})`,
        backgroundPosition: `${props.index % 2 === 0 ? "120% 28%" : "0 50%"}`,
        backgroundSize: "200%",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="planning-card-header">
        <p className="title" dangerouslySetInnerHTML={{__html: props.title }}></p>
        <div className="time">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M12 6.5625V12.5625L16 14.5625M22 12.5625C22 18.0853 17.5228 22.5625 12 22.5625C6.47715 22.5625 2 18.0853 2 12.5625C2 7.03965 6.47715 2.5625 12 2.5625C17.5228 2.5625 22 7.03965 22 12.5625Z"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p>{props.time}</p>
        </div>
      </div>
      <p dangerouslySetInnerHTML={{__html: props.description }}></p>
    </div>
  );
};

export default PlanningCard;
