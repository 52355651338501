export default function FreeIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none" style={{margin: "auto"}}>
        <g clip-path="url(#clip0_2057_92)">
            <path d="M12 23.75C18.3513 23.75 23.5 18.6013 23.5 12.25C23.5 5.89873 18.3513 0.75 12 0.75C5.64873 0.75 0.5 5.89873 0.5 12.25C0.5 18.6013 5.64873 23.75 12 23.75Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4.5 14.75V10.75C4.5 10.4848 4.60536 10.2304 4.79289 10.0429C4.98043 9.85536 5.23478 9.75 5.5 9.75H6.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4.5 12.75H6" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.5 14.75V9.75" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.5 9.75H9.25C9.58152 9.75 9.89946 9.8817 10.1339 10.1161C10.3683 10.3505 10.5 10.6685 10.5 11C10.5 11.3315 10.3683 11.6495 10.1339 11.8839C9.89946 12.1183 9.58152 12.25 9.25 12.25H8.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.5 14.75L9 12.25" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.5 14.75H13.5C13.2348 14.75 12.9804 14.6446 12.7929 14.4571C12.6054 14.2696 12.5 14.0152 12.5 13.75V10.75C12.5 10.4848 12.6054 10.2304 12.7929 10.0429C12.9804 9.85536 13.2348 9.75 13.5 9.75H14.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.5 12.75H14.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.5 14.75H17.5C17.2348 14.75 16.9804 14.6446 16.7929 14.4571C16.6054 14.2696 16.5 14.0152 16.5 13.75V10.75C16.5 10.4848 16.6054 10.2304 16.7929 10.0429C16.9804 9.85536 17.2348 9.75 17.5 9.75H18.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.5 12.75H18.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_2057_92">
            <rect width="24" height="24" fill="white" transform="translate(0 0.25)"/>
            </clipPath>
        </defs>
        </svg>
    );
}