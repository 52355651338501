interface HeroProps {
  title: string;
  subtitle?: string;
  titleStyle?: React.CSSProperties;
  subtitleStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
}

export default function Hero(props: HeroProps) {
  return (
    <div className="hero-title">
      <h1 style={props.titleStyle}>{props.title}</h1>
      <h2 style={props.subtitleStyle}>{props.subtitle}</h2>
    </div>
  );
}
