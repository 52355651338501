import PicturedDescription from "./PicturedDescription";
import photo from "../assets/photo.jpeg";
import ButtonLink from "./ButtonLink";

interface HeaderProps {
  mapUrl: string;
}

export default function AboutTheEvent(props: HeaderProps) {
    return (
        <PicturedDescription src={photo} alt="L'évènement">
            <div>
                <div style={styles.titleContainer}>
                    <h1 style={styles.title}>About the event</h1>
                    <h2 style={styles.subtitle}>
                        Genev'Hack & Limmat’Hack are the first cybersecurity conferences
                    </h2>
                </div>
                <ul style={styles.list}>
                    <li>Taking place in 2 locations Geneva + Zurich - conferences 100% free and open to all cybersecurity professionals, enthusiasts or students</li>
                    <li>Featuring more than 10 speakers</li>
                    <li>Offering a defense oriented CTF</li>
                </ul>
                <ButtonLink
                    href={props.mapUrl}
                    newTab={true}
                    style={{ maxWidth: "161px" }}>How to get there</ButtonLink>
            </div>
        </PicturedDescription>
    );
}

const styles = {
    titleContainer: {
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "flexStart",
    },
    title: {
        fontFamily: "Poppins",
        fontSize: "32px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
        background: "linear-gradient(90deg, #FFF 27.78%, #E1E1E1 100%)",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
    },
    subtitle: {
        fontFamily: "Arvo",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        marginTop: "-32px",
        maxWidth: "385px",
    },
    list: {
        maxWidth: "630px",
        marginTop: "32px",
        marginBottom: "32px",
    },
};