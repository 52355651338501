interface ButtonProps {
    children?: React.ReactNode;
    href: string;
    style?: React.CSSProperties;
    newTab?: boolean;
}

export default function ButtonLink(props: ButtonProps) {
    return (
        <a className="btn btn-orange" href={props.href} style={props.style} target={props.newTab ? "_blank" : "_self"} rel="noreferrer">{props.children}</a>
    )
}
