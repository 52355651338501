export type SpeakerCardProps = {
  firstName: string;
  lastName: string;
  title: string;
  description: string;
  image: string;
};

const SpeakerCard = (props: SpeakerCardProps) => {
  return (
    <div className="speakers-card-wrapper">
      <div className="speakers-card-img">
        <img src={props.image} height="100%" width="100%" alt={props.firstName} />
      </div>
      <span className="first-name">{props.firstName} {props.lastName}</span>
      <br></br>
      <span className="last-name">{props.title}</span>
      <p>{props.description}</p>
    </div>
  );
};

export default SpeakerCard;
