import React, { useEffect, useState } from 'react';
interface PictureDescriptionProps {
    children: React.ReactNode;
    src: string;
    alt?: string;
    imageFirst?: boolean;
}


export default function PicturedDescription(props: PictureDescriptionProps) {
    const [imageFirst, setImageFirst] = useState(props.imageFirst);

    useEffect(() => {
        const handleResize = () => {
            if (window.outerWidth < 1024) {
                setImageFirst(true);
            } else {
                setImageFirst(props.imageFirst);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [props.imageFirst]);

    return (
        <div className="desc-bg">
            {!imageFirst && (
                <img src={props.src} alt={props.alt} className="side-pic" />
            )}
            {props.children}
            {imageFirst && (
                <img src={props.src} alt={props.alt} className="side-pic" />
            )}
        </div>
    );
}
