import PicturedDescription from "./PicturedDescription";

interface EventFormatProps {
    imageSrc: string;
}

export default function EventFormat(props: EventFormatProps) {
    return (
         <PicturedDescription src={props.imageSrc} alt="L'évènement" imageFirst={true}> 
            <div>
                <div style={styles.titleContainer}>
                    <h1 style={styles.title}>Format</h1>
                </div>
                <ul style={styles.list}>
                    <li>Morning : reserved for Hacknowledge & terreActive customers (customer board)</li>
                    <li>Afternoon : Security conferences (at least 2 tracks)</li>
                    <li>Mid-afternoon : Defense oriented CTF</li>
                    <li>End of the day : Networking apero cash bar and vouchers for selected visitors (clients, prospects, sponsors...)</li>
                </ul>
            </div>
         </PicturedDescription> 
    );
}

const styles = {
    titleContainer: {
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "flexStart",
    },
    title: {
        fontFamily: "Poppins",
        fontSize: "32px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
        background: "linear-gradient(90deg, #FFF 27.78%, #E1E1E1 100%)",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
    },
    subtitle: {
        fontFamily: "Arvo",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        maxWidth: "385px",
    },
    list: {
        maxWidth: "630px",
        marginTop: "32px",
        marginBottom: "32px",
    },
};