import SpeakerCard, { SpeakerCardProps } from "../components/SpeakerCard";

type SpeakerSectionProps = {
  title: string;
  subtitle: string;
  speakers: SpeakerCardProps[];
};

const SpeakerSection = (props: SpeakerSectionProps) => {
  return (
    <>
      <div className="speakers-header">
        <h1>{props.title}</h1>
        <p>{props.subtitle}</p>
      </div>
      <div className="speakers-wrapper">
        {props.speakers.map((speaker, index) => (
          <SpeakerCard key={index} {...speaker} />
        ))}
      </div>
    </>
  );
};

export default SpeakerSection;
