interface DateProps {
    date: Date;
}

// Unfortunatly, the Date.toDateString() method
// doesn't allow us to not display the day of the week
// so we have to implement our own function
function renderDateShort(date: Date): string {
    const months: string[] = [
        "January", "February", "March",
        "April", "May", "June",
        "July", "August", "September",
        "October", "November", "December"
    ];
    return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
}

export default function EventDate(props: DateProps) {
    return (
        <h2 style={styles.date}>
            {renderDateShort(props.date)}
        </h2>
    )
}

const styles = {
    date: {
        fontWeight: 400,
        color: "#FFF",
        fontFamily: "Arvo",
        fontSize: "35px",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "3.5px",
        textAlign: "center" as "center",
        textTransform: "uppercase" as "uppercase"
    },
}