import PlanningCard, { PlanningCardProps } from "../components/PlanningCard";

type PlanningSectionProps = {
  subtitle: string;
  planning: PlanningCardProps[];
};

const PlanningSection = (props: PlanningSectionProps) => {
  let backgroundSide = 0;
  return (
    <div className="planning-section">
      <div className="planning-section-header">
        <h1>Planning</h1>
        <h2>{props.subtitle}</h2>
      </div>
      <div className="planning-wrapper">
        {props.planning.map((plan, index) => {
          (index + 1) % 2 === 0 && backgroundSide++;
          return <PlanningCard key={index} {...plan} index={backgroundSide} />;
        })}
      </div>
    </div>
  );
};

export default PlanningSection;
